var matched, browser;
jQuery.uaMatch = function (ua) {
    ua = ua.toLowerCase();

    var match = /(chrome)[ \/]([\w.]+)/.exec(ua) ||
        /(webkit)[ \/]([\w.]+)/.exec(ua) ||
        /(opera)(?:.*version|)[ \/]([\w.]+)/.exec(ua) ||
        /(msie) ([\w.]+)/.exec(ua) ||
        ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua) ||
        [];

    return {
        browser: match[1] || "",
        version: match[2] || "0"
    };
};

matched = jQuery.uaMatch(navigator.userAgent);
browser = {};

if (matched.browser) {
    browser[matched.browser] = true;
    browser.version = matched.version;
}

// Chrome is Webkit, but Webkit is also Safari.
if (browser.chrome) {
    browser.webkit = true;
} else if (browser.webkit) {
    browser.safari = true;
}

jQuery.browser = browser;


(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        define(['exports'], factory);
    } else if (typeof exports !== 'undefined') {
        factory(exports);
    } else {
        factory((root.dragscroll = {}));
    }
}(this, function (exports) {
    var _window = window;
    var _document = document;
    var mousemove = 'mousemove';
    var mouseup = 'mouseup';
    var mousedown = 'mousedown';
    var EventListener = 'EventListener';
    var addEventListener = 'add' + EventListener;
    var removeEventListener = 'remove' + EventListener;
    var newScrollX, newScrollY;
    var dragged = [];
    var reset = function (i, el) {
        for (i = 0; i < dragged.length;) {
            el = dragged[i++];
            el = el.container || el;
            el[removeEventListener](mousedown, el.md, 0);
            _window[removeEventListener](mouseup, el.mu, 0);
            _window[removeEventListener](mousemove, el.mm, 0);
        }
        dragged = [].slice.call(_document.getElementsByClassName('nav-tabs'));
        for (i = 0; i < dragged.length;) {
            (function (el, lastClientX, lastClientY, pushed, scroller, cont) {
                (cont = el.container || el)[addEventListener](
                    mousedown,
                    cont.md = function (e) {
                        if (!el.hasAttribute('nochilddrag') ||
                            _document.elementFromPoint(
                                e.pageX, e.pageY
                            ) == cont
                        ) {
                            pushed = 1;
                            lastClientX = e.clientX;
                            lastClientY = e.clientY;
                            e.preventDefault();
                        }
                    }, 0
                );
                _window[addEventListener](
                    mouseup, cont.mu = function () {
                        pushed = 0;
                    }, 0
                );
                _window[addEventListener](
                    mousemove,
                    cont.mm = function (e) {
                        if (pushed) {
                            (scroller = el.scroller || el).scrollLeft -=
                                newScrollX = (-lastClientX + (lastClientX = e.clientX));
                            scroller.scrollTop -=
                                newScrollY = (-lastClientY + (lastClientY = e.clientY));
                            if (el == _document.body) {
                                (scroller = _document.documentElement).scrollLeft -= newScrollX;
                                scroller.scrollTop -= newScrollY;
                            }
                        }
                    }, 0
                );
            })(dragged[i++]);
        }
    };
    if (_document.readyState == 'complete') {
        reset();
    } else {
        _window[addEventListener]('load', reset, 0);
    }
    exports.reset = reset;
}));

$(window).on('beforeunload', function (e) {
    setTimeout(function () {
        $('.pageloader-item').show();
        $('.pageloader-wrapper').fadeIn('fast');
    }, 600);
});

var unload_timer;
//remove preloader after page is rendered
$(window).on('load', function () {
    $('.pageloader-wrapper').fadeOut('fast');
    clearTimeout(unload_timer);
});

//forcibly remove preloader after 5 sec if page is still loading
// setTimeout(function () {
//     $('.pageloader-wrapper').fadeOut('fast');
// }, 5000);

$(document).ready(function () {
    initNavtabs();
    if ($(window).width() > 993) {
        if (getCookie('leftmenu_hide')) {
            $('body').addClass('sidenav-toggled');
        }
    } else if ($(window).width() < 993) {
        $('body').removeClass('sidenav-toggled');
    }
});

$(window).resize(function () {
    $(".nav-tabs-wrapper .nav-tabs").each(function (i) {
        initNavtabsScroll($(this));
    });
    $('.dropdown-menu-shoppingcart').css({'width': $('.section-main').width() * 0.5});
    if ($(window).width() < 993) {
        $('body').removeClass('sidenav-toggled');
    }
});

$(function () {
    "use strict";
    $('.navbar .btn-toggler').click(function (event) {
        event.preventDefault();
        $('body').toggleClass('sidenav-toggled');
        if ($(window).width() > 993) {
            setCookie('leftmenu_hide', $('body').hasClass('sidenav-toggled'), 30);
        }
    });

    if ($('.sidebar-wrap').length > 0) {
        new PerfectScrollbar('.sidebar-wrap');
    }

    if ($('.nav-tabs-wrapper .nav-tabs').length > 0) {
        new PerfectScrollbar('.nav-tabs');
    }

    if ($('.dropdown-order-items').length > 0) {
        new PerfectScrollbar('.dropdown-order-items');
    }

    if ($('.material-icons').length > 0) {
        $('.material-icons').addClass('notranslate');
    }

    if ($('.domain-tld').length > 0) {
        new PerfectScrollbar('.domain-tld');
    }

    var finditem = true,
        ahref = false;
    $('.template_2019_custom .sidebar a').removeClass('active');
    $('.template_2019_custom .nav-tabs a:not([data-toggle])').each(function () {
        var selfb = $(this);
        $('.template_2019_custom .sidebar a:not([data-toggle])').each(function () {
            var selfa = $(this);
            if (selfb.attr('href').toLowerCase() == selfa.attr('href').toLowerCase()) {
                ahref = selfa;
                finditem = false;
                return true;
            }
        });
    });
    if (finditem) {
        var chref = 0;
        $('.template_2019_custom .sidebar a:not([data-toggle])').each(function () {
            var selfa = $(this),
                hrefa = selfa.attr('href').toLowerCase();
            if (window.location.href.toLowerCase().indexOf(hrefa) >= 0) {
                if (hrefa.length > chref) {
                    chref = hrefa.length;
                    ahref = selfa;
                }
                finditem = false;
            }
        }).on('click', function (e) {
            localStorage.setItem('activeMenuItem', $(e.target).attr('href'));
        });
    }
    if (ahref)  {
        $(ahref).addClass('active');
    } else if (finditem && activeItem) {
        var activeItem = localStorage.getItem('activeMenuItem');
        if (activeItem) {
            $('.template_2019_custom .sidebar a[href="' + activeItem + '"]').addClass('active');
        }
    }

    $('.table').on('show.bs.dropdown', function (e) {
        $(e.target).closest('tr').css("overflow", "visible");
        $(this).css("overflow", "inherit");
        $(this).closest('.table-responsive').css("overflow", "visible");
    }).on('hide.bs.dropdown', function (e) {
        $(e.target).closest('tr').css("overflow", "hidden");
        $(this).css("overflow", "visible");
        $(this).closest('.table-responsive').css("overflow", "hidden");
    });

    $('#ssh-tab').on('shown.bs.tab', function (e) {
        $("#addssh-btn").show();
    }).on('hidden.bs.tab', function (e) {
        $("#addssh-btn").hide();
    });

    $('#service-filters').on('click', function () {
        var self = $(this),
            box = $('#' + self.attr('data-box'));
        $(box).fadeToggle('fast');
        self.toggleClass('shown');
    });

    $('.navbar-search').on('keyup keypress', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }).search({
        type: 'category',
        apiSettings: {
            url: '?cmd=search&query={query}',
            onResponse: function () {
                $('.navbar-search-loader').removeClass('active');
            }
        },
        showNoResults: true,
        duration: 0,
        transition: false,
        maxResults: 10,
        hideDelay: 0,
        searchDelay: 200,
        easing: false,
        minCharacters: 2,
        fields: {
            categories: 'results',     // array of categories (category view)
            categoryName: 'name',        // name of category (category view)
            categoryResults: 'results',     // array of results (category view)
            title: 'title',       // result title
            action: 'action',      // "view more" object name
            actionText: 'title',        // "view more" text
            actionURL: 'url'          // "view more" url
        },
        fullTextSearch: false,
        onSelect: function (e) {
            e.preventDefault();
        },
        onSearchQuery: function () {
            $('.navbar-search-loader').addClass('active');
        },
        onResultsAdd: function () {
            $('.navbar-search-loader').removeClass('active');
            $('.main-overlay').addClass('active');
        },
        onResultsOpen: function () {
            $('.navbar-search-results').addClass('show');
            $('.dropdown-order-items').removeClass('show');
        },
        onResultsClose: function () {
            $('.navbar-search-loader').removeClass('active');
            $('.main-overlay').removeClass('active');
        },
        selector: {
            results: '.navbar-search-results',
        },
        error: {
            source: jsLang.search.error.source ? jsLang.search.error.source : 'Cannot search. No source used, and Semantic API module was not included',
            noResults: jsLang.search.error.noResults ? jsLang.search.error.noResults : 'Your search returned no results',
            logging: jsLang.search.error.logging ? jsLang.search.error.logging : 'Error in debug logging, exiting.',
            noEndpoint: jsLang.search.error.noEndpoint ? jsLang.search.error.noEndpoint : 'No search endpoint was specified',
            noTemplate: jsLang.search.error.noTemplate ? jsLang.search.error.noTemplate : 'A valid template name was not specified.',
            oldSearchSyntax: jsLang.search.error.oldSearchSyntax ? jsLang.search.error.oldSearchSyntax : 'searchFullText setting has been renamed fullTextSearch for consistency, please adjust your settings.',
            serverError: jsLang.search.error.serverError ? jsLang.search.error.serverError : 'There was an issue querying the server.',
            maxResults: jsLang.search.error.maxResults ? jsLang.search.error.maxResults : 'Results must be an array to use maxResults setting',
            method: jsLang.search.error.method ? jsLang.search.error.method : 'The method you called is not defined.'
        }
    });

    $('.pill-select').click(function () {
        var itemsType = $(this).attr('data-items');
        var countVisible = $(this).closest('.info-box').find('.pill-select').length;
        if (itemsType === 'all') {
            $(this).closest('.info-box').find('.pill-item').slice(0, countVisible).show().addClass('__visible');
        } else {
            $(this).closest('.info-box').find('.pill-item').hide().removeClass('__visible');
            $(this).closest('.info-box').find('.pill-item[data-item="' + itemsType + '"]').slice(0, countVisible).show().addClass('__visible');
        }
    });

    $(document).on('click', function (e) {
        if ($(e.target).hasClass('navbar-order') || $(e.target).closest('.navbar-order').length) {
            e.preventDefault();
            var toggl = $('.navbar-order .navbar-order-toggler');
            $(toggl).toggleClass('show');
            if ($(toggl).hasClass('show')) {
                $('.dropdown-order-items').addClass('show');
                $('.main-overlay').addClass('active');
                $('.navbar-search-results').hide();
            } else {
                $('.dropdown-order-items').removeClass('show');
                $('.main-overlay').removeClass('active');
            }
        } else {
            $('.navbar-order .navbar-order-toggler').removeClass('show');
            $('.dropdown-order-items').removeClass('show');
            $('.main-overlay').removeClass('active');
        }
    });

    function parseFormPlaceholders(flg) {
        $(flg).find('input.form-control').each(function () {
            var s = $(this),
                h = s.val().length > 0,
                f = s.is(":focus");
            s.closest('.form-label-group').toggleClass('freeze', (h || f));
        });
    }

    $(document).on('input change focus blur click', '.form-label-group', function () {
        parseFormPlaceholders(this);
    });
    $(document).ajaxComplete(function () {
        $('.form-label-group').each(function () {
            parseFormPlaceholders(this);
        });
    });

    $('.form-label-group').each(function () {
        parseFormPlaceholders(this);
    });

    $('.vtip_description').on('click', function (e) {
        e.preventDefault();
    });

    $('.info-box-select-list').click(function (e) {
        e.preventDefault();
        var list = $(this).attr('data-select-list');
        $(this).closest('.info-boxes').find('.info-box').hide();
        $(this).closest('.info-boxes').find('.info-box[data-infobox-list="' + list + '"]').show();
    });

    $(".textarea-autoresize").autoResize();

    $('#main-side').mouseenter(function () {
        $(this).addClass('hover');
    }).mouseleave(function () {
        $(this).removeClass('hover');
    }).hover(function () {
        $('#main-side nav').height($('#page').height() - $('#section-border').outerHeight(true) - $('#main-side header').outerHeight(true))
    }, function () {
        setTimeout(function () {
            $('#main-side nav').height('');
        }, 100)
    });

    if ($('.cart-configuration').length && $('#floater').length) {
        $(window).scroll(function () {
            var t = parseInt($('.cart-configuration').offset().top);
            var f = parseInt($('#floater').offset().top);
            var scr;
            if ($(window).scrollTop() < t)
                scr = t;
            else
                scr = $(window).scrollTop();
            $('#floater').animate({top: scr + "px"}, {duration: 300});
        });
    }

    $('#board-tickets').on('click', '.ticket-list a, .ticket-controls .pull-right a', function (e) {
        e.preventDefault();
        var url = $(this).attr('href'),
            list = $('#board-tickets .ticket-list'),
            view = $('#board-tickets .ticket-view'),
            active = list.find('a[href="' + url + '"]:visible');

        $.get(url, function (data) {
            data = parse_response(data);
            if (data.length) {
                view.stop().html(data).animate({opacity: 1}, 150);
                addScrollBars(view.find('.content-scroll'));
            }
        })
        if (active.length) {
            active = active.parent();
            var scrollpos = active.prevAll(':visible').length * active.height(),
                scroloffset = list.getNiceScroll()[0].newscrolly || 0;

            active.addClass('active').siblings('.active').removeClass('active');
            view.animate({opacity: 0.2}, 500);
            if (scrollpos >= scroloffset + list.height()) {
                list.getNiceScroll().doScrollPos(0, scrollpos - list.height() + active.height(), 300);
            } else if (scrollpos < scroloffset) {
                list.getNiceScroll().doScrollPos(0, scrollpos, 300);
            }
        }
        return false;
    });

    $(document).on('click', '#dashboard_services a.nav-link', function () {
        var itms = $(this).attr('data-items');
        $('#dashboard_services_list tbody tr').addClass('hidden').hide();
        $('#dashboard_services_list tbody tr[data-item="' + itms + '"]').removeClass('hidden').show();
    });


    addScrollBars($('.content-scroll'));

    tableSplitToPages('#board-services', 'tr');
    tableSplitToPages('#board-invoices');

    $('.slide-left, .slide-right', '#board-services').click(calback_slides);
    $('.slide-left, .slide-right', '#board-invoices').click(calback_slides);

    if ($('#slides .pull-left').length > 3) {
        //divide slides to groups of 3
        var i = 0;
        var divideby = 4;
        for (i = 0; i < $('#slides .pull-left').length / divideby; i++) {
            $('#slides .pull-left').slice(i * divideby, (i * divideby) + divideby).wrapAll('<div class="slide"></div>').parent().append('<div class="clear"></div>');
        }
        $('#slides').slides({
            generatePagination: true,
            generateNextPrev: false,
            container: 'suggested-products',
            paginationClass: 'slides_pagination',
            start: 1
        });
    }

    $(document).on('click.dropdown.data-api', '[data-toggle=dropdown][data-contener]', function (e) {
        var self = $(this),
            parent = self.parent(),
            target = $(e.target)

        if (target.is('.dropdown-clone') || target.parents('.dropdown-clone').length) {
            return false;
        }

        var clone = parent.clone(true).css({
            position: 'absolute',
            top: 0,
            left: 0
        }).addClass('not-cart dropdown-clone').data('dropdown-clone', parent).appendTo(self.data('contener'));

        clone.children().each(function (i) {
            var org = parent.children().eq(i),
                elm = $(this);
            elm.css('position', 'absolute').css(org.offset()).width(org.width()).height(org.height())
        });

        clone.on('click', 'a', function () {
            clone.data('dropdown-clone').css({visibility: 'visible'});
            clone.remove();
        })
        parent.css({visibility: 'hidden'})
    }).on('click.dropdown.data-api', function () {
        $('[data-toggle=dropdown][data-contener]').each(function () {
            var self = $(this).parent();
            if (self.data('dropdown-clone')) {
                self.data('dropdown-clone').css({visibility: 'visible'});
                self.remove();
            }

        })
    });

    $(document).on('change', "#checkall", function () {
        var checkbox = $(this).closest('table').find('.checkme').not(":disabled");
        if (this.checked) {
            checkbox.prop('checked', true).trigger('change');
        } else {
            checkbox.prop('checked', false).trigger('change');
        }
    });

    $(document).on('click', '[data-sorter]', function (e) {
        e.preventDefault();
        var self = $(this),
            list = $('#currentlist'),
            url = list.attr('href'),
            sortkey = self.data('sorter'),
            sortval = self.attr('href'),
            valmatch = sortval.match(new RegExp('[&?]?' + sortkey.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '(=[^&]*)?'));
        if (valmatch) {
            sortval = valmatch[1] || '';
            url = url.replace(new RegExp('[&?]?' + sortkey.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '(=[^&]*)?'), '') + '&' + sortkey + '=' + sortval.replace(/.*?=/, '');
        } else {
            url = url.replace(new RegExp('[&?]?' + sortkey.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '(=[^&]*)?'), '') + '&' + sortkey + '=' + sortval.replace(/.*?#/, '');
        }
        list.attr('href', url);
        $('#updater').addLoader();
        ajax_update(url, {}, '#updater');
        if (sortkey == 'orderby') {
            var icon = $(self).find('.sort-icon');
            if (url.match(/\|ASC$/)) {
                self.attr('href', url.replace(/\|ASC$/, '|DESC'));
                $('.sort-icon').html('unfold_more');
                $(icon).html('expand_less');
            } else {
                self.attr('href', url.replace(/\|DESC$/, '|ASC'));
                $('.sort-icon').html('unfold_more');
                $(icon).html('expand_more');
            }
        }
        self.parent().siblings('.active').removeClass('active').end().addClass('active');
        return false;
    });

    $(document).on('change', '[data-sorter-select]', function (e) {
        e.preventDefault();
        var self = $(this),
            list = $('#currentlist'),
            url = list.attr('href'),
            sortkey = self.attr('name'),
            sortval = self.val(),
            valmatch = '';
        if (sortval) {
            valmatch = sortval.match(new RegExp('[&?]?' + sortkey.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '(=[^&]*)?'));
        }
        if (valmatch) {
            sortval = valmatch[1] || '';
            url = url.replace(new RegExp('[&?]?' + sortkey.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '(=[^&]*)?'), '') + '&' + sortkey + '=' + sortval.replace(/.*?=/, '');
        } else {
            url = url.replace(new RegExp('[&?]?' + sortkey.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '(=[^&]*)?'), '') + '&' + sortkey + '=' + sortval.replace(/.*?#/, '');
        }
        list.attr('href', url);
        $('#updater').addLoader();
        ajax_update(url, {}, '#updater');
        self.parent().siblings('.active').removeClass('active').end().addClass('active');
        $('ul[rel].pagination ').each(function () {
            pagination_li.call(this);
        });
        return false;
    });
    $(document).on('change', '[data-sorter-checkbox]', function (e) {
        e.preventDefault();
        var self = $(this),
            list = $('#currentlist'),
            url = list.attr('href'),
            sortkey = self.attr('name'),
            sortval = self.val(),
            valmatch = '';
        if (self.is(':checked')) {
            sortval = '1';
        } else {
            sortval = '';
        }
        if (sortval) {
            valmatch = sortval.match(new RegExp('[&?]?' + sortkey.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '(=[^&]*)?'));
        }
        if (valmatch) {
            sortval = valmatch[1] || '';
            url = url.replace(new RegExp('[&?]?' + sortkey.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '(=[^&]*)?'), '') + '&' + sortkey + '=' + sortval.replace(/.*?=/, '');
        } else {
            url = url.replace(new RegExp('[&?]?' + sortkey.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '(=[^&]*)?'), '') + '&' + sortkey + '=' + sortval.replace(/.*?#/, '');
        }
        list.attr('href', url);
    });

    $(document).on('click', '[data-sorter-checkbox]', function (e) {
        $(this).closest('.btn-group').addClass('show');
    });

    $(document).on('click', '#filter-apply', function () {
        var list = $('#currentlist'),
            url = list.attr('href');
        $('#updater').addLoader();
        ajax_update(url, {reset: true}, '#updater');
        $('.dropdown-filters').dropdown('toggle');
        return false;
    });

    $(document).on('click', '#filter-reset', function () {
        var list = $('#currentlist'),
            url = list.attr('href');
        $('[data-sorter-select] option:selected').removeAttr('selected');
        $('[data-sorter-select]').each(function (i, obj) {
            $(obj).val('');
            var key = $(obj).attr('name');
            url = url.replace(new RegExp('[&?]?' + key.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '(=[^&]*)?'), '');
        });
        $('[data-sorter-checkbox]').each(function (i, obj) {
            $(obj).prop('checked', false);
            var key = $(obj).attr('name');
            url = url.replace(new RegExp('[&?]?' + key.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + '(=[^&]*)?'), '');
        });
        list.attr('href', url);
        $('#updater').addLoader();
        ajax_update(url, {reset: true}, '#updater');
        $('.dropdown-filters').dropdown('toggle');
        return false;
    });

    $('[data-sorter-checkbox]').each(function (i, obj) {
        $(obj).trigger('change');
    });

    if ($('.section-affiliates-newvoucher').length > 0) {
        setTimeout(function () {
            var s = $('#slider');
            var sval = $('#sfield');
            var svalind = $('#slider_value_indicator');
            var margin = $('#slider_margin_indicator');
            if (typeof ($('#slider').slider) != 'function') {
                s.hide();
                sval.show();
                svalind.hide();
                return false;
            }

            var width = s.parent().width() - s.prevAll('span').width() - s.prevAll('div').width() - s.nextAll('span').width() - s.nextAll('div').width() - 90
            s.width(width).slider({
                min: minval,
                max: maxval,
                value: sval.val() || initialval,
                step: stepval,
                range: "min",
                animate: true,
                stop: function (e, ui) {
                    var x = Math.floor(s.slider("value"));
                    sval.val(x + 1).trigger('change');
                    svalind.html(fx.p + (x + 1) + fx.s);
                    margin.html(fx.p + ((maxval - x) + 1) + fx.s);
                }
            });
        }, 200);
    }


    var confirm = {};
    $('.confirm_js').click(function (e) {
        var self = $(this);
        if (!confirm.all_done) {
            e.preventDefault();
            var message = $(self).attr('data-confirm');
            swal({
                text: message,
                icon: "warning",
                buttons: [jsLang.no, jsLang.yes],
            }).then(function (isConfirm) {
                if (isConfirm) {
                    if ($(e.target).closest('a').length) {
                        window.location.href = $(self).attr('href');
                    } else {
                        confirm.all_done = true;
                        $(self).trigger('click');
                    }
                }
            });
        }
    });

    $('.affiliates_landing_input').bind('keyup change input', function () {
        var t = $(this),
            v = t.val(),
            b = $('.affiliates_landing_btn'),
            domain = t.data('domain');
        if (v.length && !v.match(new RegExp("^https?://(.*\.)?" + domain + "/", 'i'))) {
            t.addClass('is-invalid');
            b.prop('disabled', true);
        } else {
            t.removeClass('is-invalid');
            b.prop('disabled', false);
        }
    });

    $('.affiliates_options').on('change', 'input, select, textarea', function () {
        var self = $(this),
            inp = self.parents('tr').eq(0).find('input, select, textarea');
        inp.prop('disabled', true);
        $.post(window.location.href, {
            make: 'options',
            name: self.attr('name'),
            value: self.val()
        }, function (data) {
            parse_response(data);
            inp.prop('disabled', false)
        })
    });

    $(document).on('click', '.serlabel-ed', function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');
        var label = $(this).closest('.service-name-labeled').find('.serlabel-lb').text();
        $('#service_label_modal').find('input[name="id"]').val(id);
        $('#service_label_modal').find('input[name="servicelabel"]').val(label);
    });

    $(document).on('click', '.service_label_success', function (e) {
        e.preventDefault();
        var self = $(this);
        var modal = $('#service_label_modal');
        var data = $(self).serialize();
        var id = $(modal).find('input[name="id"]').val();
        var lb = xssFormat($(modal).find('input[name="servicelabel"]').val());
        $(modal).modal('hide');
        $.post('?cmd=clientarea&action=label', {
            id: id,
            servicelabel: lb
        }, function (data) {
            parse_response(data);
        });
        $('.serlabel-lb.service_label_' + id).html(lb);
        if (lb.length > 0) {
            $('.serlabel-sl.service_label_' + id).html(' - ');
        } else {
            $('.serlabel-sl.service_label_' + id).html('');
        }
    });

    $(document).on('click', '.domain-tld-checkbox-item', function () {
        var self = $(this),
            checkb = $(self).find('input[type="checkbox"]');

        if ($(checkb).is(':checked')) {
            $(checkb).prop('checked', false);
            $(self).removeClass('checked');

            $('.domain-tld-checkbox-all').removeClass('checked');
        } else {
            $(checkb).prop('checked', true);
            $(self).addClass('checked');
        }
    });

    $(document).on('click', '.domain-tld-checkbox-all', function () {
        var self = $(this);
        if ($(self).hasClass('checked')) {
            $('.domain-tld-checkbox-item.checked').trigger('click');
            $(self).removeClass('checked');
        } else {
            $('.domain-tld-checkbox-item').not('.checked').trigger('click');
            $(self).addClass('checked');
        }
    });

    $(document).on('keyup change', '.domain-tld-search', function () {
        var text = $(this).val();
        var all = $('.domain-tld').find(".domain-tld-checkbox-item");
        if (text !== "") {
            var els = $('.domain-tld').find(".domain-tld-checkbox-item[data-tld*='" + text + "']");
            $(all).hide();
            $(els).show();
        } else {
            $(all).show();
        }
    });
    $('#fileupload').each(function () {
        var self = $(this);

        self.fileupload({
            dataType: 'json',
            autoUpload: true,
            url: self.data('url'),
            formData: [{name: '_v', value: 2}]
        })
            .bind('fileuploadsend', function () {
                disablesubmit();
                $('.table-files').parent().show();
                $('.fileupload-progress').show();
            })
            .bind('fileuploadalways', function () {
                enablesubmit();
                $('.fileupload-progress').hide();
            })
            .bind('fileuploaddragover', showdropzone)
            .bind('fileuploaddrop', hidedropzone);
    });

    $(document).on('click', '.language-change', function (e) {
        e.preventDefault();
        $('.pageloader-item').show();
        $('.pageloader-wrapper').fadeIn('fast');
        $.get(this.getAttribute('href')).always(function () {
            window.location.reload();
        });
        return false;
    });

    initCCard();
});

$(document).ready(function () {
    if (!$('#currform').find('div.currency').length && $('#currform').find('p').length) {
        var p = $('#currform').find('p');
        var html = $(p).html();
        $('#currform').prepend('<div class="currency">' + html + '</div>');
        $(p).remove();
    }
});


function initPhoneNumberField(el) {
    var opts = {
        separateDialCode: true,
        preferredCountries: [],
        formatOnDisplay: true,
        autoFormat: false,
    };
    if (el.data('initial-country')) {
        opts.initialCountry = el.data('initial-country').toLowerCase();
    }
    $(el).intlTelInput(opts);
    $(el).closest('form').on('submit', function ()  {
        var val = $(el).val();
        if (val !== '') {
            var code = $(el).intlTelInput("getSelectedCountryData");
            var country = '+' + code['dialCode'];
            var newval = country + ' ' + val.replace(country, '');
            newval = newval.replace(/[^0-9+\s]+/gm, '');
            $(el).val(newval);
        }
    });
}

function initCCard() {
    $('.card-number_js').on('keyup change', function () {
        var type = $(this).closest('.checkout').find('.card-type_js').val();
        var card_number = $(this).val();
        if ($.isNumeric(card_number)) {
            if (type && type == 'American Express') {
                card_number = cardChunkAmex(card_number).join(' ');
            } else {
                card_number = cardChunk(card_number).join(' ');
            }
        } else {
            card_number = '';
            $(this).val('');
        }
        $(this).closest('.checkout').find('.credit-card-box-number').html(card_number);
    });

    $('.card-type_js').on('change', function () {
        var logo = $(this).closest('.checkout').find('.credit-card-box-logo');
        $(logo).attr('class', 'credit-card-box-logo');
        var type = getCreditCardTypeClass($(this).val());
        if (type != 'unknown') {
            $(logo).addClass(type);
        }
        $(this).closest('.checkout').find('.card-number_js').trigger('change');
    });

    $('.card-holder_js').on('keyup change', function () {
        $(this).closest('.checkout').find('.credit-card-box-holder div').html($(this).val());
    });

    $('.card-expiration-month_js, .card-expiration-year_js').change(function () {
        var m = $(this).closest('.checkout').find('.card-expiration-month_js').val();
        var y = $(this).closest('.checkout').find('.card-expiration-year_js').val();
        if (y && m && $.isNumeric(m) && $.isNumeric(y)) {
            $(this).closest('.checkout').find('.credit-card-box-expiration-date div').html(m + '/' + y);
        } else {
            $(this).closest('.checkout').find('.credit-card-box-expiration-date div').html('');
        }
    });

    $('.card-ccv_js').on('focus', function () {
        $(this).closest('.checkout').find('.credit-card-box').addClass('hover');
    }).on('blur', function () {
        $(this).closest('.checkout').find('.credit-card-box').removeClass('hover');
    }).on('keyup change', function () {
        if ($.isNumeric($(this).val())) {
            var number = $(this).val();
            $(this).closest('.checkout').find('.credit-card-box-ccv div').html(number);
        } else {
            $(this).closest('.checkout').find('.credit-card-box-ccv div').html($(this).val());
            $(this).val('');
        }
    });
}

function getCreditCardType(ccnumber) {
    var cc = (ccnumber + '').replace(/\s/g, '').replace('-', '');
    var result = "unknown";
    if ((/^(34|37)/).test(cc) && cc.length == 15) {
        return 'American Express'; //AMEX begins with 34 or 37, and length is 15.
    } else if ((/^(51|52|53|54|55)/).test(cc) && cc.length == 16) {
        return 'MasterCard'; //MasterCard beigins with 51-55, and length is 16.
    } else if ((/^(4)/).test(cc) && (cc.length == 13 || cc.length == 16)) {
        return 'Visa'; //VISA begins with 4, and length is 13 or 16.
    } else if ((/^(300|301|302|303|304|305|36|38)/).test(cc) && cc.length == 14) {
        return 'Dinersclub'; //Diners Club begins with 300-305 or 36 or 38, and length is 14.
    } else if ((/^(2014|2149)/).test(cc) && cc.length == 15) {
        return 'Enroute'; //enRoute begins with 2014 or 2149, and length is 15.
    } else if ((/^(6011)/).test(cc) && cc.length == 16) {
        return 'Discover'; //Discover begins with 6011, and length is 16.
    } else if ((/^(3)/).test(cc) && cc.length == 16) {
        return 'JCB';  //JCB begins with 3, and length is 16.
    } else if ((/^(2131|1800)/).test(cc) && cc.length == 15) {
        return 'JCB';  //JCB begins with 2131 or 1800, and length is 15.
    } else if ((/^(6304|6706|6771|6709)/).test(cc) && cc.length >= 16 && cc.length <= 19) {
        return 'Laser';
    } else if ((/^(5018|5020|5038|6304|6759|6761)/).test(cc) && cc.length >= 12 && cc.length <= 19) {
        return 'Maestro';
    } else if ((/^(6334|6767)/).test(cc) && cc.length >= 16 && cc.length <= 19 && cc.length != 17) {
        return 'Solo';
    }
    return result;
}

function getCreditCardTypeClass(cctype) {
    var result = "unknown";
    if (cctype == 'American Express' || cctype == 'AmericanExpress') {
        return 'amex';
    } else if (cctype == 'MasterCard' || cctype == 'Master Card' || cctype == 'Master') {
        return 'master';
    } else if (cctype == 'Visa') {
        return 'visa';
    } else if (cctype == 'Dinersclub' || cctype == 'Diners' || cctype == 'Diners Club') {
        return 'dinersclub';
    } else if (cctype == 'Discover') {
        return 'discover';
    } else if (cctype == 'JCB') {
        return 'jcb';
    } else if (cctype == 'Laser') {
        return 'laser';
    } else if (cctype == 'Maestro') {
        return 'maestro';
    }
    return result;
}

function cardChunk(str) {
    var ret = [];
    var i;
    var len;
    for (i = 0, len = str.length; i < len; i += 4) {
        ret.push(str.substr(i, 4))
    }
    return ret;
};

function cardChunkAmex(str) {
    var ret = [];
    ret.push(str.substr(0, 4));
    ret.push(str.substr(4, 6));
    ret.push(str.substr(10, 6));
    return ret;
};

function pnotify(list, type) {
    for (var i in list) {
        new PNotify({
            text: list[i],
            type: type,
            hide: false,
            sticker: false,
            icon: false
        });
    }
}

function enablesubmit() {
    $('#submitbutton').addClass('btn-success').removeClass('disabled').removeClass('btn-inverse').removeAttr('disabled');
}

function disablesubmit() {
    $('#submitbutton').removeClass('btn-success').addClass('disabled').addClass('btn-inverse').attr('disabled', 'disabled');
}

function showdropzone(e) {
    var dropZone = $('#dropzone').not('.hidden');
    dropZone.show();
    setTimeout(function () {
        hidedropzone()
    }, 6000);
}

function hidedropzone() {
    $('#dropzone').hide().addClass('hidden');
}

function switchdeptdesc(items) {
    $('.deptsdesc').children().hide();
    $('.dept_' + items).show();
    $('.dptpriority').hide().find('select').attr('disabled', 'disabled');
    $('#p' + items).show().find('select').removeAttr('disabled');
}

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
}

function tableSplitToPages(target, filter) {
    filter = filter || 'tbody tr';
    var vclass = $('table', target).attr('class'),
        table = null;

    $('table', target).hide().removeClass('ui-slide').addClass('ui-slide-disabled').find(filter).each(function (i) {
        if (i % 3 == 0) {
            table = $('<table class="' + vclass + '"></table>').addClass('ui-slide').insertAfter($('table:last', target));
            if (i > 0)
                table.hide();
        }
        $(this).show().detach().appendTo(table);
    });
    $('table', target).each(function () {
        var that = $(this);
        if (that.find('th').length)
            that.show();
        if (!that.find('tr').length)
            that.remove();
    });
}

function calback_slides(e) {
    e.preventDefault();
    var that = $(this),
        wrapper = "<div class=\"ui-slide-wrapp\"></div>",
        target = that.attr('href') || 'body',
        speed = 400;
    if (that.is('.slide-left')) {
        var t1 = $('.ui-slide:visible', target),
            t2 = t1.prev('.ui-slide'),
            direction = 'left';
        if (!t2.length)
            t2 = $('.ui-slide:last', target)
    } else {
        var t1 = $('.ui-slide:visible', target),
            t2 = t1.next('.ui-slide'),
            direction = 'right';
        if (!t2.length)
            t2 = $('.ui-slide:first', target)
    }

    if (!t1.length || !t2.length || t1.is(t2))
        return false;

    var vp = t1.parent();
    vp.height(vp.height());
    vp.width(vp.width());

    t1.fadeOut(speed);

    t2 = t2.show().wrap(wrapper).parent();
    t2.css({
        top: t1.offset().top,
        width: t1.width(),
        minHeight: t1.height()
    }).show('slide', {direction: direction}, speed, function () {
        t2.children().unwrap()
    });
    return false;
}

function xssFormat(value) {
    var lt = /</g,
        gt = />/g,
        ap = /'/g,
        ic = /"/g;
    value = value.toString().replace(lt, "&lt;").replace(gt, "&gt;").replace(ap, "&#39;").replace(ic, "&#34;");
    return value;
}

function check_i(element) {
    var td = $(element).parent();
    if ($(element).is(':checked'))
        $(td).find('input.config_val').removeAttr('disabled');
    else
        $(td).find('input.config_val').attr('disabled', 'disabled');
}

function delete_vouchers() {
    $('.vouchers-table td input[type=checkbox]:checked').each(function (index, element) {
        var that = $(this);
        var url = $(this).parents('tr').find('a').attr('href');
        $.get(url, function () {
            that.parents('tr').next('.empty-row').remove();
            that.parents('tr').remove();
        });
    });
}

function addScrollBars(set) {
    $.each(set, function () {
        var that = $(this);
        if (that.getNiceScroll().length) {
            that.getNiceScroll().resize();
            return true;
        }
        that.children().wrapAll('<div class="scrollable"></div>').end()
            .niceScroll($('.scrollable', this)[0], {
                autohidemode: true,
                cursoropacitymin: 0.25,
                cursoropacitymax: 0.8,
                hidecursordelay: 1000,
                cursorborder: '',
                cursorborderradius: '',
                background: '',
                cursorcolor: ''
            })
    });
}

function initNavtabs() {
    $(".nav-tabs-wrapper .nav-tabs").each(function (i) {
        var el = $(this);
        var arrleft = '<span class="nav-tabs-arrow-left" style="display: none;"><i class="material-icons size-lg">chevron_left</i></span>\n';
        var arrrght = '<span class="nav-tabs-arrow-right" style="display: none;"><i class="material-icons size-lg">chevron_right</i></span>\n';

        if (window.location.hash && $(el).hasClass('init-by-hash')) {
            $(el).find('a').parent().removeClass('active');
            $(el).find('a[href="' + window.location.hash + '"]').parent().addClass('active');
            $(el).find('a[href="' + window.location.hash + '"]').tab('show');
        }
        $(el).closest('.nav-tabs-wrapper').prepend(arrleft);
        $(el).closest('.nav-tabs-wrapper').append(arrrght);
        if ($(el).hasClass('nav-slider'))
            $(el).prepend('<div class="slider"><div class="indicator"></div></div>');
        else
            $(el).find('.nav-slider').prepend('<div class="slider"><div class="indicator"></div></div>');
        initNavtabsScroll($(this));
        initNavtabsSlider($(this));
        if ($(el).closest('.nav-tabs-wrapper').find('.nav-tabs-arrow-left').length > 0 && $(el).closest('.nav-tabs-wrapper').find('.nav-tabs-arrow-right').length > 0) {
            var arrowLeft = $(el).closest('.nav-tabs-wrapper').find('.nav-tabs-arrow-left');
            var arrowRight = $(el).closest('.nav-tabs-wrapper').find('.nav-tabs-arrow-right');
            var sclpx = 300; //scroll pixels
            var sclan = 100; //scroll animation, ms
            $(arrowLeft).on('click', function () {
                var scl = parseInt($(el).scrollLeft());
                $(el).animate({
                    scrollLeft: parseInt(parseInt(scl) - parseInt(sclpx))
                }, sclan);
            });

            $(arrowRight).on('click', function () {
                var scl = parseInt($(el).scrollLeft());
                $(el).animate({
                    scrollLeft: parseInt(parseInt(scl) + parseInt(sclpx))
                }, sclan);
            });
        }
        $(el).on('click', 'a.nav-link', function () {
            var m = $(this);
            var el = $(m).closest('.nav-tabs');
            $(el).find('li').removeClass('active');
            $(m).parent('li').addClass('active');
            if ($(el).hasClass('init-by-hash')) {
                window.location.hash = $(m).attr("href").substr(1);
            }
            initNavtabsSlider($(el).get(0));
        }).on('scroll', function () {
            initNavtabsSlider($(el).get(0));
            initNavtabsScroll($(el).get(0));
        });
    })
}

function initNavtabsSlider(el) {
    $(el).find(".slider").css({
        "width": parseInt(el.scrollWidth)
    });
    var m = $(el).find("li.active a");
    if (m.length) {
        var css = {
            width: $(m).width()
        };
        if ($('body').hasClass('rtl')) {
            css.right = ($(window).width() - ($(m).offset().left + $(m).outerWidth())) - ($(window).width() - ($(el).offset().left + $(el).outerWidth())) - $(el).scrollLeft();
        } else {
            css.left = $(m).offset().left - $(el).offset().left + $(el).scrollLeft() + parseInt($(m).css('marginLeft')) + parseInt($(m).css('paddingLeft'));
        }
        $(el).find(".slider .indicator").css(css);
    }
}


function initNavtabsScroll(el) {
    var width = 0;
    $(el).find('.nav-item').each(function () {
        width += parseInt($(this).outerWidth());
    });

    var arrowLeft = $(el).closest('.nav-tabs-wrapper').find('.nav-tabs-arrow-left');
    var arrowRight = $(el).closest('.nav-tabs-wrapper').find('.nav-tabs-arrow-right');

    var scr_l = Math.abs(el.scrollLeft);
    var scr_w = Math.abs(el.scrollWidth);
    var scr_r = Math.abs(scr_w - scr_l - parseInt($(el).width()));

    if (scr_l > 0 && scr_r > 0) {
        $(arrowLeft).show();
        $(arrowRight).show();
    } else if (scr_l > 0 && scr_r === 0) {
        if ($('body').hasClass('rtl')) {
            $(arrowLeft).hide();
            $(arrowRight).show();
        } else {
            $(arrowLeft).show();
            $(arrowRight).hide();
        }
    } else if (scr_l === 0 && scr_r > 0) {
        if ($('body').hasClass('rtl')) {
            $(arrowLeft).show();
            $(arrowRight).hide();
        } else {
            $(arrowLeft).hide();
            $(arrowRight).show();
        }
    } else if (parseInt(width) > parseInt($(el).closest('.nav-tabs-wrapper').width())) {
        $(arrowRight).show();
    } else {
        $(arrowLeft).hide();
        $(arrowRight).hide();
    }
}

function handle_domain_widgets(tpl) {
    var selected_domain_ids = [];

    var updater = $('#updater'),
        change_all = $('#change-all'),
        widgets = $('.domain-widgets a'),
        selected = $('.selected-names');

    function update_selected() {
        var self = $(this),
            id = self.val(),
            checked = self.is(':checked'),
            added = selected_domain_ids.hasOwnProperty(id);

        if (checked && !added) {
            var selection = $(tpl.replace('%name', self.data('name'))),
                cloned = self.clone().attr('type', 'hidden');

            selected_domain_ids[id] = selection;

            selection.data({
                rel: self,
                id: id
            });
            selection.append(cloned);
            selected.append(selection).append(' ');
        } else if (!checked && added) {
            selected_domain_ids[id].remove();
            delete selected_domain_ids[id];
        }
    }

    function update_widgets() {
        var ids = Object.keys(selected_domain_ids);

        if (!ids.length) {
            widgets.addClass('disabled');
            return;
        }

        $.getJSON('?cmd=clientarea&action=domains&make=widgetsget',
            {
                ids: ids
            },
            function (data) {
                widgets.addClass('disabled');
                widgets.filter('.widget_domainrenewal').removeClass('disabled');
                $.each(data.resp, function (index, i) {
                    widgets.filter('.widget_' + i.widget).removeClass('disabled');
                });
            }
        );
    }

    change_all.on('change', function () {
        var self = this;
        updater.find('input[type=checkbox]').each(function () {
            this.checked = self.checked;
            update_selected.call(this);
        });
        update_widgets();
    });

    widgets.on('click', function () {
        var ids = Object.keys(selected_domain_ids);
        var i, len = ids.length;

        if (!ids.length) {
            return false;
        }

        var query = '';
        for (i = 0; i < len; i++) {
            query += '&ids[]=' + ids[i]
        }

        window.location = $(this).attr('href') + query;
        return false;
    });

    updater.on('ajaxupdate', function () {
        change_all.prop('checked', false);
        updater.find('input[type=checkbox]').each(function () {
            this.checked = selected_domain_ids.hasOwnProperty(this.value);
        });
    });

    updater.on('change', 'input[type=checkbox]', function () {
        update_selected.call(this);
        update_widgets();
    });

    selected.on('click', 'a', function (e) {
        e.preventDefault();

        var self = $(this),
            rel = self.data('rel'),
            id = self.data('id');

        if ($.contains(document.documentElement, rel.get(0))) {
            rel.prop('checked', false);
        } else {
            rel.remove();
        }

        if (selected_domain_ids.hasOwnProperty(id)) {
            delete selected_domain_ids[id];
        }
        self.remove();
        update_widgets();
        return false;
    });

    change_all.prop('checked', false).trigger('change');
}
